import React, { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import loadable from "@loadable/component"
import { graphql, navigate } from "gatsby"
import { IsMobileDevice } from "../utils/functions"
import CampaignsVideo from "../components/campaigns-video"
import CampaignsContent from "../components/campaigns-content"
import BocadoModal from "../components/bocado-modal"
import BocadoCart from "../components/bocado-cart"
import Footer from "../components/footer.jsx"
import Seo from "../components/seo"
import { isMobileAtom, showCartDrawerAtom } from "../recoil/atoms.js"
import styles from "../styles/campaigns-page.module.scss"
import { campaignLinkGenerator } from "../utils/link-generator"

const Campaigns = ({ data }) => {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom)

  const [showNewsletterModal, setShowNewsletterModal] = useState(false)
  const [showFooterSuccessModal, setShowFooterSuccessModal] = useState(false)
  const [showCartDrawer, setShowCartDrawer] = useRecoilState(showCartDrawerAtom)

  const Menu = loadable(() => import("../components/menu.jsx"))

  useEffect(() => {
    let local = localStorage.getItem("age")
    let session = sessionStorage.getItem("age")
    if (!local && !session)
      navigate("/agegate", {
        state: {
          newPath: campaignLinkGenerator(data.campanas.title),
        },
      })
  }, [])

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--vh",
      `${window.innerHeight / 100}px`
    )
  }, [])

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  return (
    <div className={styles.campaignsContainer}>
      <Seo title="Stella Artois" />
      {showNewsletterModal && (
        <BocadoModal
          type="newsletter"
          isMobile={isMobile}
          setShowModal={setShowNewsletterModal}
          title="BIENVENIDO A BOCADO CLUB"
          subtitle="Registrate para poder participar de los premios y poder disfrutar de los beneficios. Enterate de las últimas novedades."
        />
      )}
      {showFooterSuccessModal && (
        <BocadoModal
          isMobile={isMobile}
          setShowModal={setShowFooterSuccessModal}
          onlySuccess={true}
        />
      )}
      <BocadoCart
        showCartDrawer={showCartDrawer}
        setShowCartDrawer={setShowCartDrawer}
      />
      <Menu
        isMobile={isMobile}
        setShowNewsletterModal={setShowNewsletterModal}
        setShowCartDrawer={setShowCartDrawer}
      />
      <CampaignsVideo video={data.campanas.video} title={data.campanas.title} />
      <CampaignsContent
        // link={"#video"}
        cardsArray={data.allCampanas.nodes}
        isMobile={isMobile}
        mainComponent={false}
      />
      <Footer
        link={campaignLinkGenerator(data.campanas.title) + "#inicio"}
        page={"novedades"}
        isMobile={isMobile}
        setShowModal={setShowFooterSuccessModal}
      />
    </div>
  )
}

export default Campaigns

export const data = graphql`
  query Campaigns($title: String) {
    campanas(title: { eq: $title }) {
      video
      title
    }
    allCampanas(filter: { title: { ne: $title } }) {
      nodes {
        video
        title
      }
    }
  }
`
