import React from "react"
import styles from "../styles/campaigns-video.module.scss"

const CampaignsVideo = ({ video, title }) => {
  return (
    <div id="inicio">
      <div className={styles.newsVideoContainer}>
        <iframe
          className={styles.newsVideoVideo}
          src={`https://www.youtube.com/embed/${video.slice(-11)}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div className={styles.newsVideoTitleContainer}>
        <div className={styles.newsVideoTitle}>{title}</div>
      </div>
    </div>
  )
}

export default CampaignsVideo
